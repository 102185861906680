import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Card } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesOffered = () => (
  <Layout pageInfo={{ pageName: "services-offered" }}>
    <SEO title="Therapy Services Offered" />
    <Container>
      <h1 className="text-center">Services Offered</h1>
      <Row>
        <Col xs={12} xl={12}>
          <Card style={{ margin: "1rem" }}>
            <Card.Body>
              <Container>
                <Row>
                  <Col xs={12} xl={6}>
                    <p>
                      Individual psychotherapy and couples counseling is
                      provided by appointment, Monday-Friday. New clients are
                      welcome to call and talk directly to the therapist. Email{" "}
                      <a href="mailto:info@jennifermarco.com">
                        {" "}
                        info{"@"}jennifermarco.com
                      </a>{" "}
                      or call <a href="tel:+16096539900">(609) 653-9900</a> for
                      more information. Your calls will be returned promptly.
                    </p>
                  </Col>
                  <Col xs={12} md={7} lg={6}>
                    <p>
                      Psychotherapy can help you addresses the following issues:
                    </p>
                    <ul>
                      <li>Anger management </li>
                      <li>Anxiety</li>
                      <li>Depressed mood </li>
                      <li>Postpartum issues</li>
                      <li>Stress management </li>
                      <li>
                        Generalized feelings of low self worth and confidence{" "}
                      </li>
                      <li>Conflicts at work, home, friendship, family </li>
                      <li>Grief counseling </li>
                      <li>Marriage and relationship </li>
                      <li>Health issues </li>
                      <li>Generalized feeling of unhappiness or anxiousness</li>
                      <li>Legal Evaluations</li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} xl={6}>
          <Card style={{ margin: "1rem" }}>
            <Card.Header>
              <h3>Techniques</h3>
            </Card.Header>
            <Card.Body>
              <p>
                Cognitive Behavioral Therapy allows clients to heighten their
                ability to <span style={{ fontWeight: "bold" }}>regulate</span>{" "}
                intense emotional reactions to stressors while utilizing more{" "}
                <span style={{ fontWeight: "bold" }}>rational</span> thinking to
                address and resolve conflict. Therapy is always personalized to
                meet the needs of each client. A confidential place to discuss
                problems is of the utmost importance.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} xl={6}>
          <Card style={{ margin: "1rem" }}>
            <Card.Header>
              <h3>Anger Management</h3>
            </Card.Header>
            <Card.Body>
              <p>
                A specialized focus in my practice is under the heading of anger
                management. I work with individual clients, a monthly anger
                management workshop for court and legal requirements, and mental
                health evaluations to address areas of conflict that are
                problematic due to emotionally charged behaviors.
              </p>

              <p>
                Triggers that promote anger are identified and the skills to
                cope productively are established.
              </p>

              <p>
                The goal is to relearn how to recognize and modify our responses
                to stressors rather than make our reactions the issue.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ServicesOffered
